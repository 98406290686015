import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import styled from 'styled-components';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import _ from 'lodash';
import { navigate } from 'gatsby';
import { isAuthenticated, updateAuth, getAuth } from 'utils/_auth.js';
import {
  getDispatchData,
  updateDispatchData,
  initDispatchData,
} from 'utils/_dispatch.js';
import { PATH_HOME, PATH_GUEST } from 'utils/constants/paths.js';
import Cookies from 'js-cookie';

const Mask = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 或者您想要的高度 */
`;

const override = {
  borderWidth: 10,
};

/* 
  1. 判斷是否已經登入，若已登入則不做任何事
  2. 若未登入，則取得登入網址並跳轉
  3. 登入完成後，會轉址回來，取得code和state
  4. 用code和state取得token
  5. 用token取得qc-auth
  6. 用qc-auth取得cookie
  7. 用token取得user資訊
  8. Logout要記得把AuthIsReady改成false
*/
const Auth = ({ location }) => {
  const loading = useLoading();
  const api = useAPI();
  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const info = useSelector((state) => getters.getInfo(state));
  const targetUrl = location.state ? location.state.targetUrl : PATH_HOME;

  const defaultDataHandler = (res) => {
    console.log('defaultDataHandler', res);
    const recommandReceiverAddress = _.get(
      res,
      'nearbyShopInfo.recommandReceiverAddress',
      ''
    );
    const recommandStoreId = _.get(res, 'nearbyShopInfo.recommandStoreId', '');
    //店別id找店別地址
    let storeAddress = '';
    if (recommandStoreId !== '') {
      const rowsShop = _.get(res, 'nearbyShopInfo.rows', []);
      rowsShop.forEach((element) => {
        if (element.id === recommandStoreId) {
          storeAddress = element.address;
        }
      });
    }
    const recommandStoreName = _.get(
      res,
      'nearbyShopInfo.recommandStoreName',
      ''
    );
    const postalCode = _.get(res, 'nearbyShopInfo.query.postalCode', '');
    const bonusThreshold = _.get(res, 'cart.bonusThreshold', 0);
    const clpeRatio = _.get(res, 'cart.clpeRatio', 0);
    const addressId = _.get(res, 'receiver.receiverAddressId', '');

    let param = {};
    const dispatchData = getDispatchData();
    if (dispatchData == null) {
      param = {
        mobile: '',
        addressId: addressId,
        storeId: recommandStoreId,
        storeName: recommandStoreName,
        shippingDate: '',
        shippingPeriod: '-1',
        shippingTimeslot: '',
        fastDeliveryPeriod: '',
        shippingModel: '1',
        receiverName: '',
        receiverPhone: '',
        receiverAddress: recommandReceiverAddress,
        receiverZip: postalCode,
        receiverElevator: '0',
        receiverReceive: '0',
        receiverAddressRemark: '',
        bonusThreshold: bonusThreshold,
        clpeRatio: clpeRatio,
        storeAddress: storeAddress,
        address: recommandReceiverAddress,
      };
    } else {
      param = {
        mobile: '',
        addressId: addressId,
        storeId: _.get(dispatchData, 'storeId', recommandStoreId),
        storeName: _.get(dispatchData, 'storeName', recommandStoreName),
        shippingDate: '',
        shippingPeriod: '-1',
        shippingTimeslot: '',
        fastDeliveryPeriod: '',
        shippingModel: _.get(dispatchData, 'shippingModel', '1'),
        receiverName: '',
        receiverPhone: '',
        receiverAddress: _.get(
          dispatchData,
          'receiverAddress',
          recommandReceiverAddress
        ),
        receiverZip: '',
        receiverElevator: '0',
        receiverReceive: '0',
        receiverAddressRemark: '',
        bonusThreshold: bonusThreshold,
        clpeRatio: clpeRatio,
        storeAddress: _.get(dispatchData, 'storeAddress', storeAddress),
        address: _.get(
          dispatchData,
          'receiverAddress',
          recommandReceiverAddress
        ),
      };
    }

    store.dispatch(actions.setShippingModel(param.shippingModel));
    initDispatchData(param);

    api.addrStandardization(param.receiverAddress).then((res) => {
      loading.dismiss();
      if (res?.length > 0) {
        updateDispatchData('receiverAddress', res[0].adjusted_address);
        updateDispatchData('floor', res[0].floor);
      }
      const baseUrl = localStorage.getItem('targetUrl');
      navigate(baseUrl, { replace: true });
    });
  };

  const getInfo = () => {
    const param = {
      customer_no: _.get(userInfo, 'customerNo', ''),
      passLocationInfo: 'false',
      passShippingInfo: 'true',
    };

    api
      .getInfo(param)
      .then((res) => {
        console.log('getInfo res', res);
        store.dispatch(actions.setInfo(res));
        defaultDataHandler(res);
      })
      .catch((error) => {
        console.log('setShippingMethod exception =', error);
      });
  };

  const getCcAuth = (onFinished) => {
    api
      .getCookie()
      .then((res) => {
        updateAuth({ ccAuth: _.get(res, 'cookie', '') });
        const { ccAuth } = getAuth();
        api.updateToken({ ccAuth });
        store.dispatch(actions.setAuthIsReady(true));
        if (onFinished) {
          onFinished();
        }
      })
      .catch((error) => {
        console.log('cookie exception =', error);
      });
  };

  useEffect(() => {
    //如果有登入來進來 就轉導回首頁
    if (isAuthenticated()) {
      navigate(PATH_HOME, { replace: true });
      return;
    }
    loading.show();
    //判斷是否沒登入且authIsReady為false
    //authIsReady用來控制頁面是否要開始render
    isAuthenticated()
      ? store.dispatch(actions.setAuthIsReady(true))
      : store.dispatch(actions.setAuthIsReady(false));

    let currentUrl = typeof window !== 'undefined' ? window.location.href : '';
    // 用問號將URL分為基本路徑和查詢字串
    const [basePath, queryString] = currentUrl.split('?');
    // 解析查詢字串
    const query = {};
    if (queryString) {
      queryString.split('&').forEach((item) => {
        const [key, value] = item.split('=');
        query[key] = decodeURIComponent(value);
      });
    }
    // access_token={access_token}&refresh_token={refresh_token}&customerNo={customerno}&qc-Auth={qcauth}&uuid={uuid}
    // 獲取 code 和 state 的值
    const accessToken = query['access_token'];
    const refreshToken = query['refresh_token'];
    const customerNo = query['customerNo'];
    const qcAuth = query['qc-Auth'];
    const uuid = query['uuid'];
    const sso = query['sso'];
    const disable = query['disable'];
    const msg = query['message'];

    console.log('accessToken', accessToken);
    console.log('refreshToken', refreshToken);
    console.log('customerNo', customerNo);
    console.log('qcAuth', qcAuth);
    console.log('uuid', uuid);

    //是代登入且有登入過就不要複寫localStorage資料
    if (sso) {
      if (Cookies.get('OcaToken')) {
        console.log('代登入且有登入過就不要複寫cookie資料');
        return;
      }
    }

    if (disable) {
      alert(msg, () => {
        navigate(PATH_GUEST, { replace: true });
      });
      return;
    }

    if (accessToken && refreshToken && customerNo && qcAuth && uuid) {
      updateAuth({
        ocaToken: accessToken,
        refreshToken: refreshToken,
        qcAuth: qcAuth,
      });

      api.updateToken({
        ocaToken: accessToken,
        refreshToken: refreshToken,
        qcAuth: qcAuth,
      });
      store.dispatch(
        actions.setUserInfo({
          ...userInfo,
          skey: _.get(query, 'skey', ''),
          customerNo: customerNo,
        })
      );

      getCcAuth(() => {
        getInfo();
      });
    } else {
      //如果沒有以上的參數，就轉導到訪客頁
      loading.dismiss();
      alert('登入失敗', () => {
        navigate(PATH_GUEST, { replace: true });
      });

      // localStorage.setItem('targetUrl', targetUrl);
      // navigate(
      //   `${process.env.GATSBY_AUTH_URL}?rurl=${currentUrl}&loginSource=QCWeb`,
      //   { replace: true }
      // );
    }
  }, []);
  return <></>;
};

export default Auth;
